var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "tableImpr",
            attrs: {
              title: "라인별 설비 목록",
              columns: _vm.gridImpr.columns,
              editable: _vm.editable && !_vm.disabled,
              data: _vm.data.dailyImproveModelList,
              merge: _vm.gridImpr.merge,
              usePaging: false,
              isExcelDown: false,
              filtering: false,
              columnSetting: false,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    props.row["sopImprovementIds"] && props.row["ibmStepNames"]
                      ? _vm._l(
                          _vm.$comm.ibmTagItems(props.row, _vm.colorItems),
                          function (item, index) {
                            return _c(
                              "q-chip",
                              {
                                key: index,
                                staticStyle: {
                                  "margin-bottom": "4px !important",
                                },
                                attrs: {
                                  color:
                                    item.ibmClassCd === "IC00000001"
                                      ? "blue-6"
                                      : "orange",
                                  clickable: true,
                                  "text-color": "white",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.linkClick(item, props.row)
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(item.title) + " "),
                                _c("c-assess-tooltip", {
                                  attrs: {
                                    ibmTooltip: item.ibmTooltip,
                                    ibmClassCd: item.ibmClassCd,
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    !_vm.disabled
                      ? _c(
                          "div",
                          [
                            _c(
                              "q-btn-group",
                              {
                                staticClass: "ColumInnerBtnGroup",
                                attrs: { outline: "" },
                              },
                              [
                                !_vm.disabled
                                  ? _c(
                                      "q-btn",
                                      {
                                        staticClass: "ColumInnerBtn",
                                        attrs: {
                                          icon: "add",
                                          color: "blue-6",
                                          "text-color": "white",
                                          align: "center",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.innerBtnClicked(
                                              col,
                                              props,
                                              "1"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("q-tooltip", [
                                          _vm._v(" 개선요청등록 "),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.disabled
                                  ? _c(
                                      "q-btn",
                                      {
                                        staticClass: "ColumInnerBtn",
                                        attrs: {
                                          icon: "add",
                                          color: "orange",
                                          "text-color": "white",
                                          align: "center",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.innerBtnClicked(
                                              col,
                                              props,
                                              "2"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("q-tooltip", [
                                          _vm._v(" 즉시조치등록 "),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }